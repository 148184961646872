export default {
    breadcrumbs: [
        'Homepage',
        'We are the Kettarians'
    ],
    h1: 'We are the Kettarians',
    text: `
        <p>The Kettari Foundation is a new generation of creative venture corporation with a focus on creators and creative industries’ products. To make a long story short - we believe in and contribute to the Creative Nation as a whole. We are sure that the Creative Nation and the creative economy potential is the basis of current and future economies, societies and states.</p>
        <p>We invest in the fastest-growing assets of our time – creators and innovators – because we believe in the importance of building a Creative Nation. Individual and collective Creators, not companies, are the brands of the future. Technologies and globally accessible Internet are the driving forces behind the Creative Nation and are forming a new breed of creative entrepreneurship worldwide.</p>
        <p>Kettari aims at developing an ecosystem of digital products – multiple IP asset management tools and services that will offer comprehensive support to the founders and drivers of creative industries and innovative technology startups at all stages of development, helping them grow their customer base and maximize profits.</p>
        <p>The Kettari Foundation helps creators and startups to define strategies for product development, product packaging, product placement, promotion, distribution and financing including market research, tracking current trends, testing product ideas, assembling teams for the most impressive projects and allocating resources to bring these ideas to life. The portfolio software solutions developed with the support of the Kettari Foundation includes multiple IP asset management tools and services which work in more than 15 countries over 4 continents.</p>
    `
}