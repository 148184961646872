export default function ({$axios, app, store, error: nuxtError, redirect}) {
    if (app.i18n.localeProperties.code) {
        $axios.setHeader('Language', app.i18n.localeProperties.code)
    }
    $axios.onResponseError(error => {
        if (error?.response?.status && error.response.status === 401) {
            // nuxtError({
            //     statusCode: error.response.status,
            //     message: error.message,
            // })
            // redirect('/logout')
        }
    })
}
