export default {
    h1: 'About',
    h3: 'Kettari Group - a Creative Nation Xanadu',
    textOne: `
        <p>The Kettari Group is a unique concept that includes:</p>
        <p>Kettari.Foundation – a foundation based in Israel, which supports creative startups in Georgia and globally and offers comprehensive support at all stages of development of creative projects</p>
        <p>Kettari.Lab – provides expertise and support as a technology accelerator and incubator for creators, startups and SMEs, as well as offering support services and solutions for participants of the Creative Industry;</p>
        <p>Kettari.Events – organizes conferences, workshops, expos, etc. and develops a community of entrepreneurs, industry leaders and decision-makers to foster a culture of creative innovation;</p>
        <p>Kettari.Education – focused on raising a new generation of highly skilled specialists and entrepreneurs to grow Creative Industries on an international level;</p>
        <p>Kettari.Services – provides support services and solutions to Creative Industry stakeholders.</p>
    `,
    textTwo: `
        <p>The Kettari Group is a new generation of venture investment with a focus on creators and creative industry products. To make a long story short — we believe in and contribute to the Creative Nation as a whole. We are sure that the Creative Nation and the creative economy potential is the basis of current and future economies, societies and states.</p>
        <p>We, the Kettarians, invest in the fastest-growing assets of our time — creators and innovators — because we believe in the importance of building a Creative Nation. Individual and collective Creators, not companies, are the brands of the future.</p>
        <p>The Kettari Group shares with Creators its Xanadu, an ecosystem of resources, knowledge, network and an international team with over 20 years of experience, to encourage new products and talent development. For us, creativity is an asset, which continually grows in value and has immense investment potential. This is why we consider intellectual property to be the Creative Nation’s equity.</p>
    `,
    textThree: `
        <p>Our focal point is to develop an ecosystem of digital products for the Creative Nation. Our portfolio includes multiple IP asset management tools and services. Our portfolio software solutions for the Creative Nation work in more in more than 15 countries on 4 continents.</p>
        <p>The Kettarians aim at developing and launching new creative products and services. We offer comprehensive support to the founders of creative industries and innovative technology startups at all stages of development, helping them grow their customer base and maximize profits. We research markets and track trends, test product ideas, assemble teams for the most impressive projects and allocate resources to bring ideas to life.</p>
    `,
    textFour: `
        <p>As a result, new creative ideas and products are generated, tested and implemented. It is important for us that every new product contributes to the overall synergy of the ecosystem of products we help create. The Kettarians also help creators and startups to define strategies for product development, product packaging, product placement, promotion, distribution and financing.</p>
        <p>The Kettari Group stimulates the prosperity of a powerful creative ecosystem. We develop physical and virtual creative industry hubs and services to help governments, businesses, and Creatives throughout the world to collaborate efficiently.</p>
    `
}