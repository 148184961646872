export default {
    h1: 'Course “The Art of Text Writing”',
    options: `
        <ul>
            <li>The course starts on February 8th</li>
            <li>Course duration: 8 lectures.</li>
            <li>Lectures will be held once a week, at 19:00</li>
            <li>Address: 139 Agmashenebeli Street, Kettari Academy</li>
        </ul>
    `,
    img: '/img/course7.png',
    text: 'The aim of the course is to teach interested people the classical structure of literature works, find out how different types of texts are understood in composition, how to highlight the faces of the characters and the development of the story. During the course, an overview will talk about various genres of literature, their main characteristics, and the main lines of development. During the practical work, the trainer will try to work individually with each listener on the texts of the genre that he is especially interested in and refine the works created in composition and stylish during the course of the course.',
    author: {
        title: 'Shota Iatashvili',
        text: 'Poet, prose writer, translator and art critic. The winner of the literary prize "Saba" in 2005, in the nomination of the best poetic collection of the year. Author of 8 poetry and 3 short story collections. His works have been translated into English, German, French, Russian, Romanian, Azerbaijani, Dutch, Portuguese, Ukrainian, Estonian, Latvian, and Albanian. Participated in international poetry festivals: ORIENT-OCCIDENT (Curt de Arges, Romania, 2006), EST-QUEST (Di, France 2006), Poetry International (Rotterdam, 2007), 5th and 6th Poetry Biennale (Moscow, 2007, 2009), SOTZIA (Tallinn, 2008), "Poetry Days" (Riga, 2008), Киевские лавры (Kiev, 2009) and others. He translated the works of Susan Sontag and American poets into Georgian.'
    },
    quote: {
        text: "The difficulty of literature is not to write, but to write what you mean",
        author: 'Robert Louis Stevenson'
    },
    join: {
        title: 'Rates:',
        desc: '600 GEL for Individuals'
    },
    modal: {
        title: 'Registered for Kettari<br> Academy course',
        buyTicket: 'Buy ticket',
        name: 'Your name',
    }
}