import locale77427e78 from '../../languages/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","name":"English","file":"en.js","dir":"ltr","pdsCode":"en"},{"code":"ge","iso":"ka-GE","name":"Georgian","file":"ge.js","dir":"ltr","pdsCode":"ka"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "languages",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","name":"English","file":"en.js","dir":"ltr","pdsCode":"en"},{"code":"ge","iso":"ka-GE","name":"Georgian","file":"ge.js","dir":"ltr","pdsCode":"ka"}],
  localeCodes: ["en","ge"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "a",
  15: "n",
  16: "g",
  17: "u",
  18: "a",
  19: "g",
  20: "e",
  21: "s",
  22: "/",
  23: "e",
  24: "n",
  25: ".",
  26: "j",
  27: "s",
  28: "\"",
  29: ",",
  30: "\"",
  31: "g",
  32: "e",
  33: ".",
  34: "j",
  35: "s",
  36: "\"",
  37: ":",
  38: "\"",
  39: ".",
  40: ".",
  41: "/",
  42: "l",
  43: "a",
  44: "n",
  45: "g",
  46: "u",
  47: "a",
  48: "g",
  49: "e",
  50: "s",
  51: "/",
  52: "g",
  53: "e",
  54: ".",
  55: "j",
  56: "s",
  57: "\"",
  58: "}",
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'ge.js': () => import('../../languages/ge.js' /* webpackChunkName: "lang-ge.js" */),
}
