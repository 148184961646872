export default {
   editProfile: 'Edit profile',
   myReservations: 'My Reservations',
   addNewReservation: 'Add a new reservation',
   notHaveReservations: 'You do not have any active reservations',
   boxes: [{
      title: 'Opportunities for startups:',
      text: `
         <ul>
            <li>Support and mentoring</li>
            <li>Services for startups - Services 360</li>
            <li>Cooperation with partners </li>
            <li>Attracting investments and financing</li>
            <li>Placement the European market</li>
            <li>Placement on the map of innovative startups in creative industries</li>
         </ul>
      `
   }, {
      title: 'Directions of services:',
      text: `
         <ul>
            <li>IP Management services</li>
            <li>IP Tax&Legal support</li>
            <li>IT services for startups</li>
            <li>Business analytics services</li>
            <li>PR&marketing for startups</li>
         </ul>
      `
   }],
   profileView: {
      text: `
         <h3>The space for 
            <br> 
            creativity & work
         </h3>
         <p>Kettari K:Lab is a co-working space located at 139 Agmashenebeli Street in Tbilisi, Georgia, that offers a range of services and amenities to support creators, digital nomads, startups and small businesses. In addition to flexible work spaces, a conference room </p>
      `,
      options: [{
         text: `
            <h4> 
               <b>Over 40 spaces for comfortable work</b>
            </h4>
         `
      }, {
         text: `
            <span>where:</span>
            <h4>139 Agmashenebeli 
               <br> 
               Street in Tbilisi
            </h4>
         `
      }],
      h3: 'Next Reservation',
      tooltip: 'Add your illustration, arts, audio, video, logos or any other creative projects<br> to the Portfolio to protect your intellectual property rights from borrowing<br> and to monetize it or get investments in future.'
   }
}