
import VButton from '~/components/ui/VButton'
import VFormGroup from '~/components/ui/VFormGroup'
import { ACADEMY_PAY } from '~/constants/api'

export default {
    data() {
        return {
            form: {
                name: '',
                phone: '',
                email: '',
                course: null,
                description: 'course payment',
            }
        }
    },
    methods: {
        getPayProperties() {
            this.$axios.post(ACADEMY_PAY, this.form).then(res => {
                this.callLiberty(res.data)
            })
        },
        callLiberty(data) {
            const query = {
                merchant: data.merchant,
                ordercode: data.ordercode,
                amount: data.amount,
                currency: data.currency,
                description: data.description,
                clientname: data.clientname,
                customdata: data.customdata,
                lng: data.lng,
                testmode: data.testmode,
                check: data.check,
                successurl: data.successurl,
                errorurl: data.errorurl,
                cancelurl: data.cancelurl,
                callbackurl: data.callbackurl,
                ispreauth: data.ispreauth,
            }
            this.hide()
            this.$router.push({
                name: 'payment-liberty',
                query: query
            })
        },
        submit() {
            this.getPayProperties()
        },
        hide() {
            this.$modal.hide('academy-join-modal')
        },
        beforeOpen(data) {
            this.form.course = data.params.course
        },
        beforeClose() {
            this.name = ''
            this.phone = ''
            this.email = ''
            this.course = null
            this.description = 'course payment'
        },
    },
    components: {
        VButton,
        VFormGroup,
    }
}
