
export default {
    props: {
        to: {
            type: String | Object,
            default: false
        },
        href: {
            type: String,
            default: ''
        },
        outlined: {
            type: Boolean,
            default: false
        },
        transparent: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'default'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        target: {
            type: String,
            default: ''
        }
    },
    computed: {
        type() {
            if (this.href) {
                return 'a'
            } else if (this.to) {
                return 'nuxt-link'
            }
            return 'button'
        },
        dynamicProps() {
            if (this.type === 'a') {
                return {
                    href: this.href,
                    target: this.target
                }
            } else if (this.type === 'nuxt-link') {
                return {
                    to: this.to
                }
            }
        }
    },
}
