export default {
    h3: 'Kettari accelerator - accelerator for the <br> development of startups from creative <br> industries',
    textOne: `
        <h4>Focus areas</h4>
        <ul>
            <li>Media (Film and video production, MusicTech, Literature and Publishing, Journalism)</li>
            <li>Design (Architecture & Urban Design, DesignTech, Furniture and Interior Design, FashionTech)</li>
            <li>Entertainment (Gaming, VR, AR, FunTech, Performing Arts, Theater, Festival activities)</li>
            <li>Science & Education (EdTech,R&D)</li>
            <li>IT (Software Development, E-Sports, CloudTech & DevOps)</li>
            <li>NFT, blockchain</li>
            <li>Other (FinTech, AdTech & MarTech, Creator Community, FoodTech, Fitness / Wellbeing)</li>
        <h4>Opportunities for startups</h4>
        <ul>
            <li>Support and mentoring</li>
            <li>Services 360: Services for startups</li>
            <li>Cooperation with partners</li>
            <li>Attracting investments and financing</li>
            <li>Entering the European market</li>
            <li>Inclusion on the map of innovative startups in creative industries</li>
    `,
    textTwo: `
        <h4>How Kettari accelerator works</h4>
        <p>We receive your application and study it for compliance with the criteria. Next, we contact you to request information if necessary. We are studying your request and will offer 3 development tracks to the teams we like: B2B Track, Investment Track, 360 Services</p>
    `,
    faq: [{
        title: 'How does the application process work?',
        text: `<p>In order to participate in the Kettari Acceleration Program please fill out our
            <a href="/startup/create" target="_blank">application</a></p>`
    }, {
        title: 'What is required from the participant in the program Kettari accelerator?',
        text: `<ul>
                <li>Filled Application</li>
                <li>Prepared Presentation based on our template</li>
            </ul>
        `
    }, {
        title: 'What type of company qualifies for Kettari accelerator?',
        text: `<p>Your company must meet the following criterias:</p>
            <ul>
                <li>Compliance with target directions</li>
                <li>Presence of a legal entity</li>
                <li>MVP availability</li>
                <li>At least 2 FTEs in a team</li>
            </ul>
        `
    }, {
        title: 'How should my startup prepare a company presentation?',
        text: `<p>Please follow the guidance in our presentation template</p>`
    }, {
        title: 'Which countries are participating in the accelerator?',
        text: `<p>Georgia, Russia, Turkey, Azerbaijan, Armenia</p>`
    }, {
        title: 'What happens in the Kettari Accelerator program?',
        text: `
            <p>
                Kettari offers a structured path to mentorship, traction, fundraising and community for founders with ambitious aspirations. 
                <br> 
                We offer 3 dedicated tracks to support startups from creative industries: 
                <br> <br> 
                <i> B2B Track</i>
                <ul>
                    <li>Communication with B2B Partners</li>
                    <li>Preparation of product presentations</li>
                    <li>Preparing teams for pitches</li>
                    <li>Product Analytics</li>
                </ul>
            </p>
            <p>   
                <i>Investment Track</i>
                <ul>
                    <li>Preparation of presentations</li>
                    <li>Preparing Teams for pitches</li>
                    <li>Business</li>
                    <li>Analytics</li>
                </ul>
            </p>
            <p>
                <i>Service Track</i>
                <ul>
                    <li>HR Services</li>
                    <li>IT Services</li>
                    <li>Tax & Legal Services</li>
                    <li>Education & Key Skills Development</li>
                </ul>
            </p>
        `
    }, {
        title: 'There are a lot of acceleration programs around, how are you different and how can you add value?',
        text: `<p>Our hands-on experience building and investing in startups from emerging markets gives us our edge. The majority of the theory being taught has been tried in the field. Our regional/global vision and network adds an element most programs cannot offer.The Accelerator Program is designed for startups looking for product market fit, while we focus on utilizing control tower methodology and support companies in getting to their proven concept faster and with fewer losses.</p>`
    }, {
        title: 'When is the Kettari Accelerator program?',
        text: `<p>Kettari has rolling application deadlines. You may submit an application at any time, and we will review it at the next application date. The sooner you apply, the easier it is to be admitted.</p>`
    }, {
        title: 'Where is the Kettari Acceleration program?',
        text: `<p>Founders participate in Kettari from wherever they choose to work and build their companies. All programming and events are designed for the best possible virtual experience.</p>`
    }]
}