
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
    data() {
        return {
            opened: false,
        }
    },
    watch: {
        '$route'() {
            this.close()
        }
    },
    computed: {
        ...mapState('profile', [
            'currentAccount'
        ]),
        ...mapGetters('profile', [
            'role'
        ]),
        languages() {
            return this.$i18n.localeCodes
        },
        currentLocale() {
            return this.$i18n.locale
        },
    },
    methods: {
        ...mapActions('profile', [
            'logout',
            'changeLanguage'
        ]),
        toggle() {
            this.opened = !this.opened
        },
        close() {
            this.opened = false
        },
        open() {
            this.opened = true
        },
        selectLang(code) {
            this.changeLanguage(code)
        },
        signIn() {
            window.open(this.glLoginUrl(), '_self');
        },
        profileClick() {
            if (this.currentAccount) {
                if (this.role === 'coworker') {
                    this.$router.push({
                        name: 'coworker-profile'
                    })
                } else {
                    this.$router.push({
                        name: 'profile'
                    })
                }
            } else {
                this.signIn()
            }
        }
    }
}
