import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _2a6c74f5 = () => interopDefault(import('../pages/academy/index.vue' /* webpackChunkName: "pages/academy/index" */))
const _08899e1e = () => interopDefault(import('../pages/accelerator.vue' /* webpackChunkName: "pages/accelerator" */))
const _368b5adc = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _67ed178c = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _12bb0ebd = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _de7c619a = () => interopDefault(import('../pages/coworker-profile/index.vue' /* webpackChunkName: "pages/coworker-profile/index" */))
const _f1273b6a = () => interopDefault(import('../pages/coworking.vue' /* webpackChunkName: "pages/coworking" */))
const _211c5b60 = () => interopDefault(import('../pages/coworking-services.vue' /* webpackChunkName: "pages/coworking-services" */))
const _21236146 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _49c57cf4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _56b4abd2 = () => interopDefault(import('../pages/meetups.vue' /* webpackChunkName: "pages/meetups" */))
const _076ad200 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _4bb7511c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _02df4938 = () => interopDefault(import('../pages/projects.vue' /* webpackChunkName: "pages/projects" */))
const _47f47d70 = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _211f388a = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _0406ba31 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _0f5f4263 = () => interopDefault(import('../pages/sign-up-pds.vue' /* webpackChunkName: "pages/sign-up-pds" */))
const _16c09fe6 = () => interopDefault(import('../pages/sign-up-profile.vue' /* webpackChunkName: "pages/sign-up-profile" */))
const _d61e0f48 = () => interopDefault(import('../pages/ois/create.vue' /* webpackChunkName: "pages/ois/create" */))
const _6372eb8d = () => interopDefault(import('../pages/payment/cancel.vue' /* webpackChunkName: "pages/payment/cancel" */))
const _3cc9e476 = () => interopDefault(import('../pages/payment/error.vue' /* webpackChunkName: "pages/payment/error" */))
const _f1b51a18 = () => interopDefault(import('../pages/payment/liberty.vue' /* webpackChunkName: "pages/payment/liberty" */))
const _1f9f5100 = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _9b53d4cc = () => interopDefault(import('../pages/profile/edit.vue' /* webpackChunkName: "pages/profile/edit" */))
const _658fc790 = () => interopDefault(import('../pages/startup/create.vue' /* webpackChunkName: "pages/startup/create" */))
const _26a270d4 = () => interopDefault(import('../pages/user/email/verify.vue' /* webpackChunkName: "pages/user/email/verify" */))
const _0029a915 = () => interopDefault(import('../pages/ois/edit/_id.vue' /* webpackChunkName: "pages/ois/edit/_id" */))
const _7e84fd2e = () => interopDefault(import('../pages/events/_id.vue' /* webpackChunkName: "pages/events/_id" */))
const _67925968 = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _5313698a = () => interopDefault(import('../pages/ois/_id.vue' /* webpackChunkName: "pages/ois/_id" */))
const _6d658e5a = () => interopDefault(import('../pages/profile/_id.vue' /* webpackChunkName: "pages/profile/_id" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0e433b93,
    caseSensitive: true,
    name: "about"
  }, {
    path: "/academy",
    component: _2a6c74f5,
    caseSensitive: true,
    name: "academy"
  }, {
    path: "/accelerator",
    component: _08899e1e,
    caseSensitive: true,
    name: "accelerator"
  }, {
    path: "/auth",
    component: _368b5adc,
    caseSensitive: true,
    name: "auth"
  }, {
    path: "/blog",
    component: _67ed178c,
    caseSensitive: true,
    name: "blog"
  }, {
    path: "/contacts",
    component: _12bb0ebd,
    caseSensitive: true,
    name: "contacts"
  }, {
    path: "/coworker-profile",
    component: _de7c619a,
    caseSensitive: true,
    name: "coworker-profile"
  }, {
    path: "/coworking",
    component: _f1273b6a,
    caseSensitive: true,
    name: "coworking"
  }, {
    path: "/coworking-services",
    component: _211c5b60,
    caseSensitive: true,
    name: "coworking-services"
  }, {
    path: "/events",
    component: _21236146,
    caseSensitive: true,
    name: "events"
  }, {
    path: "/logout",
    component: _49c57cf4,
    caseSensitive: true,
    name: "logout"
  }, {
    path: "/meetups",
    component: _56b4abd2,
    caseSensitive: true,
    name: "meetups"
  }, {
    path: "/news",
    component: _076ad200,
    caseSensitive: true,
    name: "news"
  }, {
    path: "/profile",
    component: _4bb7511c,
    caseSensitive: true,
    name: "profile"
  }, {
    path: "/projects",
    component: _02df4938,
    caseSensitive: true,
    name: "projects"
  }, {
    path: "/services",
    component: _47f47d70,
    caseSensitive: true,
    name: "services"
  }, {
    path: "/sign-in",
    component: _211f388a,
    caseSensitive: true,
    name: "sign-in"
  }, {
    path: "/sign-up",
    component: _0406ba31,
    caseSensitive: true,
    name: "sign-up"
  }, {
    path: "/sign-up-pds",
    component: _0f5f4263,
    caseSensitive: true,
    name: "sign-up-pds"
  }, {
    path: "/sign-up-profile",
    component: _16c09fe6,
    caseSensitive: true,
    name: "sign-up-profile"
  }, {
    path: "/ois/create",
    component: _d61e0f48,
    caseSensitive: true,
    name: "ois-create"
  }, {
    path: "/payment/cancel",
    component: _6372eb8d,
    caseSensitive: true,
    name: "payment-cancel"
  }, {
    path: "/payment/error",
    component: _3cc9e476,
    caseSensitive: true,
    name: "payment-error"
  }, {
    path: "/payment/liberty",
    component: _f1b51a18,
    caseSensitive: true,
    name: "payment-liberty"
  }, {
    path: "/payment/success",
    component: _1f9f5100,
    caseSensitive: true,
    name: "payment-success"
  }, {
    path: "/profile/edit",
    component: _9b53d4cc,
    caseSensitive: true,
    name: "profile-edit"
  }, {
    path: "/startup/create",
    component: _658fc790,
    caseSensitive: true,
    name: "startup-create"
  }, {
    path: "/user/email/verify",
    component: _26a270d4,
    caseSensitive: true,
    name: "user-email-verify"
  }, {
    path: "/ois/edit/:id?",
    component: _0029a915,
    caseSensitive: true,
    name: "ois-edit-id"
  }, {
    path: "/events/:id",
    component: _7e84fd2e,
    caseSensitive: true,
    name: "events-id"
  }, {
    path: "/news/:id",
    component: _67925968,
    caseSensitive: true,
    name: "news-id"
  }, {
    path: "/ois/:id?",
    component: _5313698a,
    caseSensitive: true,
    name: "ois-id"
  }, {
    path: "/profile/:id",
    component: _6d658e5a,
    caseSensitive: true,
    name: "profile-id"
  }, {
    path: "/",
    component: _2dfb1658,
    caseSensitive: true,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
