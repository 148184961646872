export default {
    banner: {
        title: 'Kettari Foundation',
        description: 'You always get whatever you want - sooner or later, one way or another',
        join: 'Join Us'
    },
    creative: {
        title: 'Build the Creative <br> with the Kettari Group',
        items: [{
            name: 'Creators',
            text: '<p>We invest in Creators because we understand that they are the fastest-growing asset of our time, and we believe in the importance of building a Creative Nation. Individual and collective Creators are the brands of the future.</p><p>Our mission is to ensure the most comfortable environment for them and support them on every stage of the creative process.</p>'
        }, {
            name: 'Companies',
            text: '<p>We help startups to define strategies for product development, product packaging, product placement, promotion, distribution and financing. We also support in market research, strategic planning, testing product ideas, assembling teams for the most impressive projects and allocating resources.</p><p>All these efforts bring the ideas to life and as a result grow companies’ business and maximize profits.</p>'
        }]
    },
    economy: {
        title: 'The Creative <br> Economy in Numbers',
        items: [{
            title: '2 trillion <br> dollars',
            description: 'generated by creative <br> industries globally'
        },{
            title: '30 million <br> people',
            description: 'employed in creative <br> industries all over the world'
        },{
            title: '3-5%',
            description: 'the creative industries <br> contribution to GDP <br> in emergin countries'
        }]
    },
    info: {
        items: [{
            title: 'Accelerator',
            description: 'For the development <br> of startups from creative industries',
            text: `<h3>Opportunities for startups:</h3>
                <ul>
                    <li>Support and mentoring</li>
                    <li>Services for startups - Services 360</li>
                    <li>Cooperation with partners </li>
                    <li>Attracting investments and financing</li>
                    <li>Entering the European market</li>
                    <li>Will get on the map of innovative startups in creative industries</li>
                </ul>
            `
        },{
            title: 'Services 360',
            description: 'Is a set of services for <br> maximum acceleration of startups in creative industries',
            text: `<h3>Directions of services</h3>
                <ul>
                    <li>IP Management services</li>
                    <li>IP Tax&Legal support</li>
                    <li>IT services for startups</li>
                    <li>Bussiness analytics services</li>
                    <li>PR&marketing for startups</li>
                </ul>
            `
        }]
    },
    join: {
        title: 'Be the <br> Kettarian',
        send: 'Send Application',
        text: `let's make the world <br> brighter`
    },
    city: {
        pretitle: 'Welcome to the',
        title: 'Kettarian city',
        text: 'The main idea is to establish a unified innovative and creative urban enviroment, providging technology, products and services for the creative and innovate community to live, develop and communicate, following the concept of 360 for bussines and life.'
    },
    map: {
        dots: {
            about: {
                title: 'About',
                text: 'The Kettari Foundation is a new generation of venture investment with a focus on creators and creative industry products.'
            },
            accelerator: {
                title: 'Accelerator',
                text: 'For the development of startups from creative industries.'
            },
            services: {
                title: 'Services',
                text: 'Is a set of services for maximum acceleration of startups in creative industries.'
            },
            portfolio: {
                title: 'Portfolio',
                text: 'Our focal point is to develop an ecosystem of digital products for the Creative Nation.'
            }
        }
    },
    media: {
        items: [{
            selector: 'Blogs',
            title: 'We are the Kettarians',
            description: 'The Kettari Group is a new generation of creative venture corporation with a focus on creators and creative industries’ products.'
        },{
            selector: 'Stories',
            title: 'M. Jackson Rules',
            description: 'I have always wanted to create music that will influence and inspire future generations. Well, really, who cares about being mortal?'
        },{
            selector: 'Video',
            title: 'Levan Lefsveridze',
            description: 'Inventor and innovator Levan Lefsveridze is a guest of the Kettari Internet podcast.'
        }]
    },
    partners: {
        text: 'Our focal point is to develop an ecosystem of digital products for the Creative Nation. The Kettarians know how to build a trust infrastructure and a global “data lake” in the IP sphere where Creators secure, sell and protect their IP rights and items digitally. And this is not just talk! Our portfolio of projects  includes multiple IP asset management tools and services based on this trust infrastructure.',
        items: [{
            text: 'An AI&ML-based turnkey solution by Global Music Monitoring (GMM), customised for live performance monitoring of radio and TV broadcasting, streaming services and podcasts.'
        }, {
            text: 'A universal blockchain-based solution that allows to create a multifunctional infrastructure for building up a modern intellectual property institute and to enforce its performance as a participant of the digital market economy.'
        }, {
            text: 'An AL&ML-based service for <br> monitoring broadcast TV, radio, <br> streaming services, as well as live <br> perfomance and internet broadcasting'
        }, {
            text: 'Service for depositing source code and UX/UI design elements to protect the creators’ intellectual property rights.'
        }, {
            text: 'An ecosystem for the protection of intellectual property assets via digital depositing.'
        }]
    }
}