export default {
   h1: 'Registration',
   h3: 'Welcome to the Kettari Foundation',
   type: 'Choose type of profile',
   roleKettari: {
      label: 'Kettari member',
      tooltip: `I’m creator and wish to upload my creative profile as well as to use digital tools to protect and monetize my intellectual property items.`,
   },
   roleCoworker: {
      label: 'Coworking member',
      tooltip: `I wish to rent a space at co-working and get an access to other office facilities.`
   },
   successText: 'We have sent an email to confirm your registration',
   agreement: 'I agree to the processing of personal data',
   privacyText: `I agree to the <a href="/files/Privacy_Policy.pdf" target="_blank">Privacy Policy</a>`,
   termsText: `I agree to the <a href="/files/Terms_of_use.pdf" target="_blank">Terms of Use</a>`
}