
import TheHeader from '~/components/base/TheHeader'
import TheModals from '~/components/base/TheModals'
import TheFooter from '~/components/base/TheFooter'
import global from '~/mixins/global'
import Vue from 'vue'
import { mapGetters } from 'vuex'
Vue.mixin(global);

export default {
    computed: {
        ...mapGetters('profile', [
            'role'
        ])
    },
    components: {
        TheHeader,
        TheModals,
        TheFooter
    }
}
