export default {
    h1: 'Portfolio projects',
    h2: 'Our focal point is to develop an ecosystem of digital products for the Creative Nation. The Kettarians know how to build a trust infrastructure and a global “data lake” in the IP sphere where Creators secure, sell and protect their IP rights and items digitally. And this is not just talk! Our portfolio of projects includes multiple IP asset management tools and services based on this trust infrastructure.',
    blocks: [{
        text: `
            <p>A universal blockchain-based solution that allows to create a multifunctional infrastructure for building up a modern intellectual property institute and to enforce its performance as a participant of the digital market economy.</p>
            <ul>
                <li>3 nodes;</li>
                <li>45K+ transactions;</li>
                <li>Trust infrastructure in the IP sphere, creating a global “data lake,” where the participants act as guarantors;</li>
                <li>Distributed data registry based on HyperLedger technology</li>
            </ul>
        `
    }, {
        text: `
            <p>An AI&ML-based service for monitoring radio and TV broadcasting, streaming services and live performance and Internet broadcasting.</p>
            <ul>
                <li>70K+ radio & TV streams in 127 countries;</p>
                <li>100mln+ digital fingerprints in the database;</p>
                <li>28ms to analyze 5sec recording frame;</p>
                <li>In-house developed monitoring equipment to ensure 24/7 broadcast coverage;</p>
                <li>Consolidated reports enriched by metadata including artist/track/album name, release date, label and ISRC.</p>
            </ul>
            <p>Service geography: Germany, Italy, Georgia, Lithuania, Ukraine, Albania, Colombia, Ghana.</p>
        `
    }, {
        text: `
            <p>Service for depositing source code and UX/UI design elements to protect the creators’ intellectual property rights.</p>
            <ul>
                <li>20К+ source code repositories;</li>
                <li>6K+ design projects;</li>
                <li>Depositing directly from integrated development environments and plug-in interface development services;</li>
                <li>Version control of projects;</li>
                <li>Searching for code in public repositories;</li>
                <li>Checking code for instances of borrowing;</li>
            </ul>
            <p>Service geography: USA, Germany, Spain, Netherlands, Poland, Czech Republic, Georgia, Armenia, Belarus.</p>
        `
    }, {
        text: `
            <p>An AI&ML-based turnkey solution by Global Music Monitoring (GMM), customised for live performance monitoring of radio and TV broadcasting, streaming services and podcasts.</p>
            <p>In 2019, GMM customised its in-house developed music monitoring and recognition technology to handle the process of live performance analysis and matching, and became the exclusive technological partner of GWVR, Germany (the “Gesellschaft fur die Wahrnehmung von Veranstalterrenchten” was founded to let event promoters participate in the revenues that are generated from concert recording).</p>
            <ul>
                <li>90+ public broadcasters of Germany;</li>
                <li>900+ private broadcasters of Germany;</li>
                <li>1.1mln+ hours of live performances broadcasted;</li>
                <li>2bln+ views of live performances via streaming services.</li>
            </ul>
        `
    }, {
        text: `
            <p>An ecosystem for the protection of intellectual property assets via digital depositing</p>
            ul
                <li>For many types of IP items: music, videos, audiovisual works, texts, pictures, 3D models, inventions, technologies, industrial design, construction documents, trademarks;</li<
                <li>Proof of authorship;</li<
                <li>Flexible access customization and tuning for various categories of users;</li<
                <li>Digital notary;</li<
                <li>Automated analysis of downloaded IP item for its uniqueness (to exclude the fact of full or partial illegal borrowing).</li<
            <p>Features to be launched later:</p>
                <ul>
                    <li>A smart solution for searching and tracking cases of illegal uses of music, audiovisuals, texts, pictures, and trademarks on the Internet with automatic claims handling;</li<
                    <li>The International Standard Name Identifier (ISNI) registration;</li<
                    <li>The International Standard Recording Code (ISRC) assignment.</li<
                </ul>
        `
    }],
    h5: 'Georgia Startup Map'
}