export const BookingTime = () => import('../../components/booking/BookingTime.vue' /* webpackChunkName: "components/booking-time" */).then(c => wrapFunctional(c.default || c))
export const BookingTimeItem = () => import('../../components/booking/BookingTimeItem.vue' /* webpackChunkName: "components/booking-time-item" */).then(c => wrapFunctional(c.default || c))
export const BaseTheFooter = () => import('../../components/base/TheFooter.vue' /* webpackChunkName: "components/base-the-footer" */).then(c => wrapFunctional(c.default || c))
export const BaseTheHeader = () => import('../../components/base/TheHeader.vue' /* webpackChunkName: "components/base-the-header" */).then(c => wrapFunctional(c.default || c))
export const BaseTheHeaderMobile = () => import('../../components/base/TheHeaderMobile.vue' /* webpackChunkName: "components/base-the-header-mobile" */).then(c => wrapFunctional(c.default || c))
export const BaseTheModals = () => import('../../components/base/TheModals.vue' /* webpackChunkName: "components/base-the-modals" */).then(c => wrapFunctional(c.default || c))
export const CoworkingService = () => import('../../components/coworking/CoworkingService.vue' /* webpackChunkName: "components/coworking-service" */).then(c => wrapFunctional(c.default || c))
export const HomepageBanner = () => import('../../components/homepage/HomepageBanner.vue' /* webpackChunkName: "components/homepage-banner" */).then(c => wrapFunctional(c.default || c))
export const HomepageCity = () => import('../../components/homepage/HomepageCity.vue' /* webpackChunkName: "components/homepage-city" */).then(c => wrapFunctional(c.default || c))
export const HomepageContainer = () => import('../../components/homepage/HomepageContainer.vue' /* webpackChunkName: "components/homepage-container" */).then(c => wrapFunctional(c.default || c))
export const HomepageCreative = () => import('../../components/homepage/HomepageCreative.vue' /* webpackChunkName: "components/homepage-creative" */).then(c => wrapFunctional(c.default || c))
export const HomepageDialog = () => import('../../components/homepage/HomepageDialog.vue' /* webpackChunkName: "components/homepage-dialog" */).then(c => wrapFunctional(c.default || c))
export const HomepageEconomy = () => import('../../components/homepage/HomepageEconomy.vue' /* webpackChunkName: "components/homepage-economy" */).then(c => wrapFunctional(c.default || c))
export const HomepageEvents = () => import('../../components/homepage/HomepageEvents.vue' /* webpackChunkName: "components/homepage-events" */).then(c => wrapFunctional(c.default || c))
export const HomepageInfo = () => import('../../components/homepage/HomepageInfo.vue' /* webpackChunkName: "components/homepage-info" */).then(c => wrapFunctional(c.default || c))
export const HomepageJoin = () => import('../../components/homepage/HomepageJoin.vue' /* webpackChunkName: "components/homepage-join" */).then(c => wrapFunctional(c.default || c))
export const HomepageMap = () => import('../../components/homepage/HomepageMap.vue' /* webpackChunkName: "components/homepage-map" */).then(c => wrapFunctional(c.default || c))
export const HomepageMedia = () => import('../../components/homepage/HomepageMedia.vue' /* webpackChunkName: "components/homepage-media" */).then(c => wrapFunctional(c.default || c))
export const HomepagePartners = () => import('../../components/homepage/HomepagePartners.vue' /* webpackChunkName: "components/homepage-partners" */).then(c => wrapFunctional(c.default || c))
export const CoworkerNewReservation = () => import('../../components/coworker-profile/CoworkerNewReservation.vue' /* webpackChunkName: "components/coworker-new-reservation" */).then(c => wrapFunctional(c.default || c))
export const CoworkerReservation = () => import('../../components/coworker-profile/CoworkerReservation.vue' /* webpackChunkName: "components/coworker-reservation" */).then(c => wrapFunctional(c.default || c))
export const FaqItem = () => import('../../components/faq/FaqItem.vue' /* webpackChunkName: "components/faq-item" */).then(c => wrapFunctional(c.default || c))
export const EventCalendar = () => import('../../components/event/EventCalendar.vue' /* webpackChunkName: "components/event-calendar" */).then(c => wrapFunctional(c.default || c))
export const EventCard = () => import('../../components/event/EventCard.vue' /* webpackChunkName: "components/event-card" */).then(c => wrapFunctional(c.default || c))
export const EventItem = () => import('../../components/event/EventItem.vue' /* webpackChunkName: "components/event-item" */).then(c => wrapFunctional(c.default || c))
export const LayoutContainer = () => import('../../components/layout/Container.vue' /* webpackChunkName: "components/layout-container" */).then(c => wrapFunctional(c.default || c))
export const MeetupItem = () => import('../../components/meetup/MeetupItem.vue' /* webpackChunkName: "components/meetup-item" */).then(c => wrapFunctional(c.default || c))
export const ModalsAcademyJoinModal = () => import('../../components/modals/AcademyJoinModal.vue' /* webpackChunkName: "components/modals-academy-join-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsDynamicTextModal = () => import('../../components/modals/DynamicTextModal.vue' /* webpackChunkName: "components/modals-dynamic-text-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsSmsModal = () => import('../../components/modals/SmsModal.vue' /* webpackChunkName: "components/modals-sms-modal" */).then(c => wrapFunctional(c.default || c))
export const NewsItem = () => import('../../components/news/NewsItem.vue' /* webpackChunkName: "components/news-item" */).then(c => wrapFunctional(c.default || c))
export const NewsItemBig = () => import('../../components/news/NewsItemBig.vue' /* webpackChunkName: "components/news-item-big" */).then(c => wrapFunctional(c.default || c))
export const OisForm = () => import('../../components/ois/OisForm.vue' /* webpackChunkName: "components/ois-form" */).then(c => wrapFunctional(c.default || c))
export const PortfolioItem = () => import('../../components/portfolio/PortfolioItem.vue' /* webpackChunkName: "components/portfolio-item" */).then(c => wrapFunctional(c.default || c))
export const ProfileView = () => import('../../components/profile/ProfileView.vue' /* webpackChunkName: "components/profile-view" */).then(c => wrapFunctional(c.default || c))
export const UiVButton = () => import('../../components/ui/VButton.vue' /* webpackChunkName: "components/ui-v-button" */).then(c => wrapFunctional(c.default || c))
export const UiVCheckbox = () => import('../../components/ui/VCheckbox.vue' /* webpackChunkName: "components/ui-v-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiVDatePicker = () => import('../../components/ui/VDatePicker.vue' /* webpackChunkName: "components/ui-v-date-picker" */).then(c => wrapFunctional(c.default || c))
export const UiVFormGroup = () => import('../../components/ui/VFormGroup.vue' /* webpackChunkName: "components/ui-v-form-group" */).then(c => wrapFunctional(c.default || c))
export const UiVInput = () => import('../../components/ui/VInput.vue' /* webpackChunkName: "components/ui-v-input" */).then(c => wrapFunctional(c.default || c))
export const UiVInputBox = () => import('../../components/ui/VInputBox.vue' /* webpackChunkName: "components/ui-v-input-box" */).then(c => wrapFunctional(c.default || c))
export const UiVLabel = () => import('../../components/ui/VLabel.vue' /* webpackChunkName: "components/ui-v-label" */).then(c => wrapFunctional(c.default || c))
export const UiVRadio = () => import('../../components/ui/VRadio.vue' /* webpackChunkName: "components/ui-v-radio" */).then(c => wrapFunctional(c.default || c))
export const UiVSelect = () => import('../../components/ui/VSelect.vue' /* webpackChunkName: "components/ui-v-select" */).then(c => wrapFunctional(c.default || c))
export const UiVTooltip = () => import('../../components/ui/VTooltip.vue' /* webpackChunkName: "components/ui-v-tooltip" */).then(c => wrapFunctional(c.default || c))
export const ModalsBookingCoworkingBookingLayout = () => import('../../components/modals/booking/CoworkingBookingLayout.vue' /* webpackChunkName: "components/modals-booking-coworking-booking-layout" */).then(c => wrapFunctional(c.default || c))
export const ModalsBookingEquipmentBooking = () => import('../../components/modals/booking/EquipmentBooking.vue' /* webpackChunkName: "components/modals-booking-equipment-booking" */).then(c => wrapFunctional(c.default || c))
export const ModalsBookingMeetingBooking = () => import('../../components/modals/booking/MeetingBooking.vue' /* webpackChunkName: "components/modals-booking-meeting-booking" */).then(c => wrapFunctional(c.default || c))
export const ModalsBookingSubscriptionsBooking = () => import('../../components/modals/booking/SubscriptionsBooking.vue' /* webpackChunkName: "components/modals-booking-subscriptions-booking" */).then(c => wrapFunctional(c.default || c))
export const ModalsBookingWorkplaceBooking = () => import('../../components/modals/booking/WorkplaceBooking.vue' /* webpackChunkName: "components/modals-booking-workplace-booking" */).then(c => wrapFunctional(c.default || c))
export const UiVDropdownGrouped = () => import('../../components/ui/VDropdown/Grouped.vue' /* webpackChunkName: "components/ui-v-dropdown-grouped" */).then(c => wrapFunctional(c.default || c))
export const UiVDropdownSimple = () => import('../../components/ui/VDropdown/Simple.vue' /* webpackChunkName: "components/ui-v-dropdown-simple" */).then(c => wrapFunctional(c.default || c))
export const UiVFileField = () => import('../../components/ui/VFile/VFileField.vue' /* webpackChunkName: "components/ui-v-file-field" */).then(c => wrapFunctional(c.default || c))
export const UiVFileItem = () => import('../../components/ui/VFile/VFileItem.vue' /* webpackChunkName: "components/ui-v-file-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
