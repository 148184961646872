import pagesOis from '~/languages/en/pages/ois'
import pagesNews from '~/languages/en/pages/news'
import pagesBlog from '~/languages/en/pages/blog'
import pagesIndex from '~/languages/en/pages/index'
import pagesAbout from '~/languages/en/pages/about'
import pagesEvents from '~/languages/en/pages/events'
import pagesSignIn from '~/languages/en/pages/sign-in'
import pagesSignUp from '~/languages/en/pages/sign-up'
import pagesMeetups from '~/languages/en/pages/meetups'
import pagesStartup from '~/languages/en/pages/startup'
import pagesAcademy from '~/languages/en/pages/academy'
import pagesServices from '~/languages/en/pages/services'
import pagesContacts from '~/languages/en/pages/contacts'
import pagesProjects from '~/languages/en/pages/projects'
import pagesCoworking from '~/languages/en/pages/coworking'
import pagesAccelerator from '~/languages/en/pages/accelerator'
import pagesSignUpProfile from '~/languages/en/pages/sign-up-profile'
import pagesCoworkerProfile from '~/languages/en/pages/coworker-profile'
import pagesCoworkingServices from '~/languages/en/pages/coworking-services'

export default {
    pages: {
        ois: pagesOis,
        news: pagesNews,
        blog: pagesBlog,
        index: pagesIndex,
        about: pagesAbout,
        events: pagesEvents,
        signIn: pagesSignIn,
        signUp: pagesSignUp,
        academy: pagesAcademy,
        meetups: pagesMeetups,
        startup: pagesStartup,
        services: pagesServices,
        contacts: pagesContacts,
        projects: pagesProjects,
        coworking: pagesCoworking,
        accelerator: pagesAccelerator,
        signUpProfile: pagesSignUpProfile,
        coworkerProfile: pagesCoworkerProfile,
        coworkingServices: pagesCoworkingServices,
    },
    general: {
        academy: 'Academy',
        profileEditing: 'Profile editing',
        stayTuned: 'Stay Tuned',
        joinUs: 'Join Us',
        bePartner: 'Be a Partner',
        imStartup: `I'm a Startup`,
        news: 'News',
        allNews: 'All news',
        learnMore: 'Learn More',
        events: 'Events',
        allEvents: 'All events',
        media: 'Media',
        partners: 'Partners',
        ourProjects: 'Our projects',
        services: 'Services',
        startup: 'Startup', 
        accelerator: 'Accelerator',
        meetups: 'Meetups',
        coworking: 'Coworking',
        profile: 'Profile',
        portfolio: 'Portfolio',
        signIn: 'Sign In',
        signUp: 'Sign Up',
        address: 'Address',
        phone: 'Phone',
        email: 'Email',
        company: 'Company',
        aboutUs: 'About Us',
        contacts: 'Contacts',
        privacyPolicy: 'Privacy policy',
        termsOfUse: 'Terms of Use',
        more: 'More',
        search: 'Search',
        all: 'All',
        industry: 'Industry',
        cancel: 'Cancel',
        send: 'Send',
        congratulations: 'Congratulations',
        ok: 'OK',
        password: 'Password',
        repeatPassword: 'Repeat password',
        logIn: 'Log in',
        register: 'Register',
        or: 'or',
        firstName: 'First name',
        lastName: 'Last name',
        dateOfBirth: 'Date of birth',
        gender: 'Gender',
        phoneNumber: 'Phone number',
        employee: 'Employee',
        entrepreneur: 'Entrepreneur',
        typeOfDocument: 'Type of document',
        document: 'Document',
        job: 'Job',
        creativeIndustries: 'Creative Industries',
        tags: 'Tags',
        requiredFields: 'required fields',
        profilePhoto: 'Profile photo',
        documentScans: 'Document scans',
        bio: 'Bio',
        recommended: 'Recommended',
        addToFavourites: 'Add to favourites',
        join: 'Join',
        deposite: 'Deposite',
        publish: 'Publish',
        edit: 'Edit',
        delete: 'Delete',
        description: 'Description',
        makeReservation: 'Make a reservation',
        addNewReservation: 'Add a new reservation',
        bookingNumber: 'Booking number',
        reserve: 'Reserve',
        seeMore: 'See more',
        free: 'Free',
        pay: 'Pay',
        type: 'Type',
        space: 'Space',
        time: 'Time',
        date: 'Date',
        starts: 'Starts',
        entedCodeFromSms: 'Enter code from SMS',
        smsModalText: `
            To verify phone number, enter the code 
            <br> 
            from the text message
        `,
        sendAgain: 'Send again',
        sendAgainIn: 'Send again in',
        sec: 'sec', // seconds
        smsErrorText: 'SMS code has already been sent and is valid for 10 minutes',
        serverError: 'An unexpected error has occurred',
        by: 'by',
        upload: 'Upload',
        role: 'Role',
        fieldIsRequired: 'Field is required',
        author: 'Author',
        coAuthor: 'Co-author',
        addAuthor: 'Add author',
        addRightsholder: 'Add rightsholder',
        uploadCover: 'Upload cover',
        save: 'Save',
        published: 'Published',
        created: 'Created',
        deposited: 'Deposited',
        allReservations: 'All reservations',
        addIpAsset: 'Add IP Asset',
        noData: 'No data',
        image: 'Image',
        video: 'Video',
        audio: 'Audio',
        text: 'Text',
        multiType: 'Multi-type',
        uploadFile: 'Upload file',
        composite: 'Composite',
        multiTypeFiles: 'Image, Video, Audio, Text file',
        until: 'until',
        logout: 'Log out',
    },
    booking: {
        booking: 'Minimal time to book a meeting room is 1 hour',
        oneTime: 'Please choose your membership type',
        yourBookingNumber: 'Your booking number',
        dateOfVisit: 'Date of visit',
        roomType: 'Room type',
        bookNow: 'Book now',
        file: 'Invalid file format or size',
        successTransaction: 'Successful transaction',
        awaitingReceipt: 'Awaiting for receipt',
        backToBooking: 'OK',
        errorTransaction: 'Transaction error',
        tryAgainLater: 'Try again later',
        cancelTransaction: 'Transaction cancelled',
        paymentReceived: 'Payment received',
        waitingBank: 'Waiting for bank confirmation',
        checkBackLater: 'Check back later',
        returnMoney: 'If something goes wrong, the money will be returned to the card',
    },
    error: {
        phone: 'Please enter a valid phone number',
        repeatPassword: 'Passwords do not match',
        percentMax: 'Percent amount cannot be more than 100%',
        percentMin: 'Percent cannot be less than 100%'
    },
    footer: {
        contacts: [{
            text: `<p>Israel, 12 Ben Zvi Yitzhak Beer Sheva, 8489330,</p><p>Georgia, 4 Chovelidze street, 0108 Tbilisi</p>`
        }, {
            text: `<p>Israel - 972 39 155 615</p>`
        }, {
            text: `<p>info@kettari.com</p>`
        }],
        copyright: 'Copyright 2023<br> Kettari Foundation'
    }
}