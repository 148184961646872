export default {
    banner: {
        h2: 'Kettari K:Lab <br> is a co-working space',
        text: 'We support creators, digital nomads, startups and small businesses.',
    },
    about: {
        title: 'The space for creativity & work',
        text: `Kettari K:Lab is a co-working space located at 139 Agmashenebeli Street in Tbilisi, Georgia, that offers a range of services and amenities to support creators, digital nomads, startups and small businesses. In addition to flexible work spaces, a conference room, and a presentation space, Kettari K:Lab also offers a streaming service that allows businesses to broadcast their presentations, pitches, keynote speeches, online shows, or music performances on Kettari's digital platforms and social media.`,
    },
    booking: {
        tooltip: 'In this section you can book coworking services',
        placeholder: 'Coworking name'
    },
    event: {
        text: `<h3>Flexible pricing <br> approach</h3>
            <p>Kettari K:Lab has a range of pricing options to suit the needs of different businesses, including daily, weekly, and monthly rates for use of the work spaces and conference room, as well as special rates for use of the presentation space or streaming service. Kettari K:Lab may also offer discounts for longer-term commitments or for businesses that are members of certain organizations or associations.</p>
        `,
        options: [{
            text: '<b>Over 40 spaces for comfortable work</b>',

        }, {
            span: 'where:',
            text: '139 Agmashenebeli <br> Street in Tbilisi'
        }, {
            span: 'when:',
            text: 'Every day <br> from 8:00 until 22:00'
        }]
    },
    text: {
        title: 'Kettari K:Lab is dedicated to supporting the growth and development of startups and small businesses, providing them with the facilities and opportunities they need to succeed.'
    }
}