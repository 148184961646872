export default {
    title: 'Filing an application',
    form: {
        name: {
            label: 'Startup name *',
            placeholder: 'Startup name'
        },
        presentation: {
            label: 'Product presentation',
        },
        description: {
            label: 'Describe what your company does in no more than 3 sentences. give us your best elevator pitch: *'
        },
        productStateDescription: {
            label: 'Development stage'
        },
        tracks: {
            label: 'Tracks'
        },
        support: {
            label: 'What problem or use case does your company address? *'
        }
    },
    searchPlaceholder: 'Search users',
    createSuccessText: 'You have successfully created an application',
}