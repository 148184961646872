export default {
    filter: {
        location: 'Event location',
        search: {
            placeholder: 'Search event',
            label: 'Search',
        },
    },
    eventDate: 'Date of event',
    resetFilters: 'Reset filters',
    similarEvents: 'Similar events',
}