
import VButton from '~/components/ui/VButton'
import TheHeaderMobile from '~/components/base/TheHeaderMobile'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            langOpened: false,
        }
    },
    computed: {
        ...mapState('profile', [
            'currentAccount'
        ]),
        ...mapGetters('profile', [
            'role'
        ]),
        languages() {
            return this.$i18n.localeCodes
        },
        currentLocale() {
            return this.$i18n.locale
        },
    },
    methods: {
        ...mapActions('profile', [
            'logout',
            'changeLanguage'
        ]),
        openLang() {
            this.langOpened = true
        },
        closeLang() {
            this.langOpened = false
        },
        signIn() {
            window.open(this.glLoginUrl(), '_self');
        },
        handleLogout() {
            this.logout()
        },
        selectLang(code) {
            this.changeLanguage(code)
            this.closeLang()
        }
    },
    components: {
        VButton,
        TheHeaderMobile
    }
}
