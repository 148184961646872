
import Container from '~/components/layout/Container'
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('profile', [
            'role'
        ])
    },
    components: {
        Container
    }
}
