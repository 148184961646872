export default {
    title: 'Startup services',
    content: {
        text: 'Services 360 - is a set of services for maximum acceleration of startups in creative industries.',
        title: 'About services',
    },
    blocks: [{
        title: '“IT services for startups” includes:',
        text: `<ul>
                <li>Information security</li>
                <li>Creation and audit of a package of technical and project documentation</li>
                <li>Audit and evaluation of server and network infrastructure.</li>
                <li>Devops</li>
                <li>Audit and design of information systems (software architecture, roadmaps, data infrastructure, etc.)</li>
                <li>Assistance in the selection of a tech stack (justification of the pros and cons when choosing certain basic technologies)</li>
                <li>Legally significant assessment of licensing risks of using intellectual property (including libraries) in already developed software</li>
                <li>Audit and design of monitoring systems, rapid response to incidents, organization of technical support</li>
            </ul>
        `
    }, {
        title: '“IP Management services” includes:',
        text: `<ul>
                <li>IP patenting fast track</li>
                <li>IP landscape research</li>
                <li>IP depositing</li>
                <li>IP valuation services</li>
                <li>IP protection</li>
                <li>Products from Aetis, Algo</li>
            </ul>
        `
    }, {
        title: '“IP Tax & Legal support” includes:',
        text: `<ul>
                <li>Legal services on intellectual property</li>
                <li>Legal services for data protection</li>
                <li>Patents in Georgia and abroad</li>
                <li>Trademark registration</li>
                <li>Conducting patent analytic</li>
                <li>Computer programs</li>
                <li>Industrial design</li>
                <li>Courts and disputes</li>
            </ul>
        `
    }, {
        title: '“Business analytics services” includes:',
        text: `<ul>
                <li>Market Guiding</li>
                <li>Financial Consulting</li>
                <li>Strategy & Product Development</li>
                <li>Brand and Creative</li>
                <li>Customer Engagement and Digital Design</li>
                <li>Business Operation Design and Execution</li>
                <li>PR Analytics</li>
                <li>Sources of data</li>
            </ul>
        `
    }, {
        title: '“PR & marketing for startup" includes',
        text: `<ul>
                <li>PR services</li>
                <li>New media, social networks, bloggers and influencers</li>
                <li>Analytics, research and strategy</li>
                <li>Entering the international market</li>
                <li>Personal brand for manager and experts</li>
            </ul>
        `
    }]
}