export const rules = {
    common: {
        message: (params) => 'Fill in the field correctly'
    },
    mask: {
        message: (params) => 'Fill in the field correctly'
    },
    required: {
        message: (params) => 'Field is required'
    },
    minLength: {
        message: (params) => `Minimum number of characters - ${params.min}`
    },
    email: {
        message: (params) => 'Please enter a valid email'
    },
    password: {
        message: (params) => 'The password must contain at least 8 Latin letters and numbers (at least 1 number, 1 lowercase and 1 uppercase letter)'
    },
    date: {
        message: (params) => 'Please enter a valid date YYYY-MM-DD',
    },
    repeatPassword: {
        message: (params) => 'Passwords must match'
    }
}

export function getMessage(rule, params) {
    return rules?.[rule]?.message(params) || 'Unknown error';
}
