export default function ({ store, next, redirect }) {
    if (store.state.profile.isAuthorized && !store.getters['profile/accountIsFullFilled']) {
        redirect({
            name: 'sign-up-pds'
        })
    }
    if (!store.state.profile.isAuthorized) {
        redirect('/sign-up')
    }
}
