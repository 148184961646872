
import { mixFieldValidate } from '~/mixins/validate';
import VLabel from '~/components/ui/VLabel'

export default {
    mixins: [mixFieldValidate],
    props: {
        validate: {
            type: Object,
            default: null,
        },
        value: {
            type: [String, Number, Boolean, Object, Array],
            default: ''
        },
        validateErrorMessages: {
            type: Object,
        },
        label: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'input'
        },
        required: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ''
        },
        maxlength: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            tag: '',
            focused: false,
        }
    },
    computed: {
        hasError() {
            return (this.validate && this.validate.$error && this.validate.$dirty) || !!this.error
        }
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value);
        },
        handleFocus() {
            this.focused = true;
            this.$emit('focus');
        },
        handleBlur() {
            this.focused = false;
            this.$emit('blur');
        }
    },
    components: {
        VSelect: () => import('~/components/ui/VSelect'),
        VInput: () => import('~/components/ui/VInput'),
        VDatePicker: () => import('~/components/ui/VDatePicker'),
        VLabel,
    }
}
