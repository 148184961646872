import {
    USER_LOGOUT,
    USER_REFRESH,
    SIGN_UP,
    CHANGE_LANGUAGE
} from '~/constants/api';

const state = () => {
    return {
        currentAccount: null,
        isAuthorized: false,
        signToken: null,
    }
}

const getters = {
    accounts: (state) => state.currentAccount ? state.currentAccount.accounts : null,
    activeAccount: (state, getters) => getters.accounts ? getters.accounts.find(el => el.is_active) : null,
    accountIsFullFilled: (state) => state.currentAccount.is_rules && state.currentAccount.is_agreement,
    role: (state) => state.currentAccount?.role
}

const actions = {
    setCurrentAccount({ dispatch, commit }, data) {
        this.$i18n.setLocale(data.item.lang)
        commit('SET_CURRENT_ACCOUNT', {
            ...data.item,
            lang: data.item.lang
        })
        commit('SET_AUTHORIZED', true)
    },
    async getUser() {
        return await this.$axios(SIGN_UP)
    },
    setAuthorized({ commit }, payload) {
        commit('SET_AUTHORIZED', payload)
    },
    async refresh() {
        return await this.$axios(USER_REFRESH)
    },
    async logout({ commit }) {
        this.$router.push('/')
        const res = await this.$axios.post(USER_LOGOUT)
        commit('SET_CURRENT_ACCOUNT', null)
        commit('SET_AUTHORIZED', false)
        return res
    },
    changeLanguage({commit}, code) {
        this.$axios.post(`${CHANGE_LANGUAGE}/${code}`).then(res => {
            commit('SET_LANGUAGE', code)
        }).finally(() => {
            this.$i18n.setLocale(code)
            if (location) {
                location.reload()
            }
        })
    },
}

const mutations = {
    SET_LANGUAGE(state, code) {
        if (state.currentAccount) {
            state.currentAccount.lang = code
        }
    },
    SET_CURRENT_ACCOUNT(state, account) {
        state.currentAccount = account
    },
    SET_AUTHORIZED(state, payload) {
        state.isAuthorized = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
