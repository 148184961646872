const middleware = {}

middleware['accessRoleKettari'] = require('../middleware/accessRoleKettari.js')
middleware['accessRoleKettari'] = middleware['accessRoleKettari'].default || middleware['accessRoleKettari']

middleware['authenticate'] = require('../middleware/authenticate.js')
middleware['authenticate'] = middleware['authenticate'].default || middleware['authenticate']

middleware['hasAccount'] = require('../middleware/hasAccount.js')
middleware['hasAccount'] = middleware['hasAccount'].default || middleware['hasAccount']

middleware['isAuth'] = require('../middleware/isAuth.js')
middleware['isAuth'] = middleware['isAuth'].default || middleware['isAuth']

middleware['isNotAuth'] = require('../middleware/isNotAuth.js')
middleware['isNotAuth'] = middleware['isNotAuth'].default || middleware['isNotAuth']

export default middleware
