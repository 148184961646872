export default {
    uploadIpAsset: 'Upload IP Asset',
    editIpAsset: 'Edit IP Asset',
    certificateFileDownload: 'Certificate file download',
    ipAssetFileDownload: 'IP asset file download',
    depositeTooltip: 'To activate the IP deposit service, enter your ID number and upload a scan of the document to your profile',
    successText: 'IP asset has been published',
    deletedText: 'IP asset has been deleted',
    form: {
        name: {
            label: 'IP asset name/title *',
            placeholder: 'Name/title',
        },
        type: {
            label: 'Type of IP asset *',
        },
        authorPercent: {
            label: `Share of author's contribution`
        },
        ownerPercent: {
            label: 'Share of rightsholder’s contribution',
        },
        rightholderIsAuthor: {
            label: 'The rightholders is the same as the authors'
        },
        isPublic: {
            label: 'I consent to publish the IP asset in my portfolio'
        }
    },
    tooltip: 'After creating the IP item entry in your personal account, you are always able to upload your IP asset file at a later and more convenient time'
}