import { COWORKING_MAKE } from "~/constants/api"

const state = () => {
    return {
        bookingData: {
            space: '',
            date: '',
            typeEquipment: '',
            times: [],
            cells: [],
            room: '',
            duration: 'day',
            price: null,
            calculatedPrice: null,
        }
    }
}

const getters = {
}

const actions = {
    bookingClean({ commit }) {
        commit('CLEAN_DATA')
    },
    async bookingPaySystem({ commit }, data) {
        const query = {
            merchant: data.merchant,
            ordercode: data.ordercode,
            amount: data.amount,
            currency: data.currency,
            description: data.description,
            clientname: data.clientname,
            customdata: data.customdata,
            lng: data.lng,
            testmode: data.testmode,
            check: data.check,
            successurl: data.successurl,
            errorurl: data.errorurl,
            cancelurl: data.cancelurl,
            callbackurl: data.callbackurl,
            ispreauth: data.ispreauth,
        }
        this.$router.push({
            name: 'payment-liberty',
            query: query
        })
        // let formData = new FormData()
        // Object.entries(query).forEach(entry => {
        //     let key = entry[0]
        //     let value = entry[1]
        //     formData.append(key, value)
        // })
        // const queryString = new URLSearchParams(query).toString()
        // this.$axios({
        //     method: 'post',
        //     url: 'https://www.pay.ge/pay',
        //     data: formData,
        //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
        // })
    },
    async bookingPay({ state, dispatch }, item) {
        if (item.type === 'one_time') {
            return await this.$axios.post(COWORKING_MAKE, {
                service_id: item.id,
                price_id: state.bookingData.price.id,
                date_from: state.bookingData.date
            })
        }
        return await this.$axios.post(COWORKING_MAKE, {
            service_id: item.id,
            times: state.bookingData.times
        }) 
    },
    changeData({ commit }, data) {
        commit('CHANGE_DATA', data)
    }
}

const mutations = {
    CLEAN_DATA(state) {
        state.bookingData = {
            space: '',
            date: '',
            typeEquipment: '',
            times: [],
            cells: [],
            room: '',
            duration: 'day',
            price: null,
            calculatedPrice: null,
        }
    },
    CHANGE_DATA(state, data) {
        state.bookingData[data.field] = data.value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
