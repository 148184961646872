
import SmsModal from '~/components/modals/SmsModal'
import AcademyJoinModal from '~/components/modals/AcademyJoinModal'

export default {
    components: {
        SmsModal,
        AcademyJoinModal
    }
}
