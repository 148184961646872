import { FILE_VIEW } from '~/constants/api'
import _ from 'lodash'

const accepts = [{
    type: 'image',
    value: 'image/*'
},{
    type: 'video',
    value: 'video/*',
},{
    type: 'text',
    value: 'text/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text',
},{
    type: 'audio',
    value: 'audio/*',
}]

export default {
    computed: {
        oisTypes() {
            return [
                {
                    title: this.$t('general.image'),
                    value: 'image',
                    icon: 'image',
                    accept: accepts.find(el => el.type === 'image').value,
                    description: 'JPEG, PNG',
                    maxSize: 30,
                },
                {
                    title: this.$t('general.video'),
                    value: 'video',
                    icon: 'video',
                    accept: accepts.find(el => el.type === 'video').value,
                    description: 'MP4, AVI, MOV, MKV, VOB, FLV',
                    maxSize: 5000,
                },
                {
                    title: this.$t('general.text'),
                    value: 'text',
                    icon: 'text',
                    accept: accepts.find(el => el.type === 'text').value,
                    description: 'TXT, PDF, DOC, DOCX',
                    maxSize: 30,
                },
                {
                    title: this.$t('general.audio'),
                    value: 'audio',
                    icon: 'audio',
                    accept: accepts.find(el => el.type === 'audio').value,
                    description: 'MP3, OGG, WAV, AIFF, APE, FLAC',
                    maxSize: 100,
                },
                {
                    title: this.$t('general.multiType'),
                    value: 'composite',
                    accept: accepts.map(el => el.value).join(','),
                    description: this.$t('general.multiTypeFiles'),
                    maxSize: 5000,
                }
            ]
        }
    },
    methods: {
        glCreateCost(value, currency = 'RUB', options = {}, locale = 'ru-RU') {
            const number = Number(value);
            if (isNaN(value)) {
                console.error('glCreateCost работает только с числами', typeof value, value);
            } else {
                return number.toLocaleString(locale, {
                    ...options,
                    ...(() => currency ? {
                        style: 'currency',
                        currency,
                    } : false)()
                })
            }
        },
        noun(number, titles) {
            const cases = [2, 0, 1, 1, 1, 2]
            return titles[
                number % 100 > 4 && number % 100 < 20
                    ? 2
                    : cases[number % 10 < 5 ? number % 10 : 5]
            ]
        },
        glLoginUrl() {
            console.log(this.$i18n);
            const regUri = this.$config.NODE_ENV === 'local' ? '' : `${window.location.origin}/sign-up`;
            return `${this.$config.IPCHAIN_PDS}${this.$config.IPCHAIN_REDIRECT_URL}&reg_uri=${regUri}&lang=${this.$i18n.localeProperties.pdsCode}`;
        },
        serverFileStreamUrl(id) {
            return `${this.$config.PUBLIC_API}/file/stream/${id}`;
        },
        serverFileUrl(id) {
            return `${this.$config.PUBLIC_API}/${FILE_VIEW}/${id}`;
        },
        serverImage(id, route = '') {
            return `${this.$config.PUBLIC_API}/${route ? route : FILE_VIEW}/${id}`;
        },
        prepareQueryParams(filters, serverDataFilters) {
            let dataObject = {}
            serverDataFilters.forEach(el => {
                const searchQuery = filters[el.fieldName]
                const searchEnum = el?.enums?.find(el => el.value === searchQuery)
                if (searchQuery !== '' && searchQuery !== undefined) {
                    if (searchEnum && searchEnum.queryPart) {
                        dataObject[searchEnum.queryPart.split('=')[0]] = searchEnum.queryPart.split('=')[1]
                    } else {
                        dataObject[`${el.queryPart}${el.queryOptions ? `[${el.queryOptions[0]}]` : '[eq]'}`] = searchQuery
                    }
                }
            })
            return dataObject
        },
        debounce: _.debounce(function(func){
            func()
        }, 500),
    }
}
