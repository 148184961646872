export const SIGN_UP = 'user';
export const CHANGE_LANGUAGE = 'user/lang'
export const SIGN_IN = 'user/login';
export const USER_REFRESH = 'user/refresh';
export const USER_LOGOUT = 'user/logout';
export const USER_FILL = 'user/fill';
export const FILE_UPLOAD = 'user/file/upload';
export const FILE_VIEW = 'file';
export const VIEW_ACCOUNT = (id) => `account/${id}`;
export const UPDATE_ACCOUNT = (id) => `user/account/${id}`;
export const USER_VERIFY_SMS_CODE = 'user/verify/send';
export const CREATE_ACCOUNT = 'user/account/individual';
export const GET_ACCOUNTS = 'user/account'
export const EMAIL_CONFIRM = (token) => `user/email/verify?token=${token}`;
export const IP = 'ip';
export const MY_IP_LIST = 'ip/my';
export const IP_PUBLISH = (id) => `ip/${id}/publish`
export const NEWS = 'news';
export const EVENTS = 'kevent/list-grouped';
export const EVENTS_AVAILABLE = 'kevent/available';
export const EVENT = 'kevent';
export const SIMILAR_EVENTS = (id) => `kevent/${id}/similar`;
export const FILTER_GROUP = 'filter/group';
export const ENUMS = 'enums';
export const STARTUP = 'startup';
export const ACADEMY_PAY = 'academy/pay'
export const ACADEMY_COURSE = 'academy/course'
export const PAYMENT_STATUS = 'payment/status'
export const PAYMENT_SUCCESS = 'payment/success'
export const PAYMENT_CANCEL = 'payment/cancel'
export const PAYMENT_ERROR = 'payment/error'
export const COWORKING_SERVICES = 'coworking/services';
export const COWORKING_MAKE = 'coworking/orders';
export const COWORKING_ORDERS = 'coworking/orders';
export const COWORKING_SPACE = 'coworking/spaces'
