
import TheModals from '~/components/base/TheModals'
import global from '~/mixins/global'
import { mapGetters } from 'vuex'
import Vue from 'vue'
Vue.mixin(global);

export default {
    computed: {
        ...mapGetters('profile', [
            'role'
        ])
    },
    components: {
        TheModals
    }
}
