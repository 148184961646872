import { getMessage } from '~/constants/validate-messages';

export const mixFieldValidate = {
    data() {
        return {
            vuelidateErrorText: '',
        }
    },
    watch: {
        validate: {
            handler() {
                this.vuelidateErrorMessage()
            },
            deep: true
        }
    },
    methods: {
        vuelidateErrorMessage() {
            this.vuelidateErrorText = ''
            if (this.validate && this.validate.$model !== undefined && this.validate.$error) {
                const validations = this.validate.$params && Object.keys(this.validate.$params)
                const typeError = validations.find(
                    (property) => this.validate[property] === false
                )
                this.vuelidateErrorText = this?.validateErrorMessages?.[typeError] ? this.validateErrorMessages[typeError] : getMessage(typeError, this.validate.$params[typeError]);
            }
        }
    }
}